<!--
 * @Description: 客户详情
 * @Author: ChenXueLin
 * @Date: 2021-10-21 10:51:33
 * @LastEditTime: 2022-07-26 13:35:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="客户基础信息" name="one">
          <base-info ref="baseInfo"></base-info>
        </el-tab-pane>
        <el-tab-pane label="客户联系人" name="two">
          <customer-contact ref="customerContact"></customer-contact>
        </el-tab-pane>
        <el-tab-pane label="信用与风险信息" name="three">
          <risk-info :creditMsg="corpBaseInfo"></risk-info>
        </el-tab-pane>
        <!-- <el-tab-pane label="全景图" name="four">
          全景图
        </el-tab-pane>
        <el-tab-pane label="客户图谱" name="five">
          <tree-chart :json="data" :class="{ landscape: 0 }"></tree-chart>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="业绩分成" name="six">
          <results-ratio></results-ratio>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="修改日志" name="seven">
          <edit-log></edit-log>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import BaseInfo from "./baseInfo.vue";
import riskInfo from "./riskInfo.vue";
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import CustomerContact from "@/views/OMS/customerManage/contactManage/list";
import { printError } from "@/utils/util";
import { getCorpDetail } from "@/api";
export default {
  name: "CustomerDetail",
  mixins: [base, listPage, listPageReszie],
  components: { BaseInfo, riskInfo, CustomerContact },
  data() {
    return {
      activeName: "one",
      corpId: "",
      corpBaseInfo: {}, //客户信息
      corpInvoiceInfo: {} //开票信息
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    // 初始化表单
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.corpId = hasParamsRoute && hasParamsRoute.params.corpId;
      if (this.corpId) {
        this.getCorpDetail();
        this.$nextTick(() => {
          this.$refs.customerContact.formCustomerListInit();
        });
      }
    },
    //获取客户详情
    async getCorpDetail() {
      try {
        this.loading = true;
        let res = await getCorpDetail({ corpId: this.corpId });
        if (res.code !== "OK") {
          return this.$message.error(res.msg);
        }
        let { corpBaseInfo, corpInvoiceInfo, corpSourceSystem } = res.data;
        corpBaseInfo.corpId = this.corpId;
        corpBaseInfo.corpSourceSystem = corpSourceSystem;
        if (!corpBaseInfo.corpSource) {
          corpBaseInfo.corpSource = "";
        }
        if (!corpBaseInfo.corpCategory) {
          corpBaseInfo.corpCategory = "";
        }
        //开票信息
        this.$nextTick(() => {
          this.$refs.baseInfo.initInvoiceData(corpInvoiceInfo, corpBaseInfo);
          this.$refs.baseInfo.initeBaseInfoData(corpBaseInfo);
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-tabs {
  height: 100%;
  .el-tabs__content {
    height: calc(100% - 47px);
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
